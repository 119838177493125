import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  subMenuIcon: { minWidth: theme.spacing(5) },
  subMenuArrow: {
    position: 'absolute',
    left: 210,
  },
  collapseListSpaced: {
    '& a': {
      paddingLeft: theme.spacing(5),
    },
  },
  collapseListCompact: {
    '& a': {
      paddingLeft: theme.spacing(2),
    },
  },
  subMenuClosed: {
    maxWidth: theme.spacing(5),
  },
}));
