import { AutocompleteInput, NullableBooleanInput, ReferenceInput, TextInput } from 'react-admin';

import { Resource } from '../../../utils/enums';

export const customerAreasFilters = [
  <TextInput key="name" source="name" alwaysOn={true} resettable={true} />,
  <ReferenceInput
    key="clientId"
    source="clientId"
    reference={Resource.companies}
    alwaysOn={true}
    allowEmpty={false}
    resettable={true}
    sort={{ field: 'name', order: 'ASC' }}>
    <AutocompleteInput filterToQuery={(searchText) => (searchText ? { name: searchText } : {})} debounce={500} />
  </ReferenceInput>,
  <ReferenceInput
    key="providerId"
    source="providerId"
    reference={Resource.providers}
    alwaysOn={true}
    allowEmpty={false}
    resettable={true}
    sort={{ field: 'name', order: 'ASC' }}>
    <AutocompleteInput filterToQuery={(searchText) => (searchText ? { name: searchText } : {})} debounce={500} />
  </ReferenceInput>,
  <NullableBooleanInput key="enabled" alwaysOn source="enabled" />,
];
