import { TextInput, useTranslate } from 'react-admin';

function filterDeviceReference(value) {
  // Only apply filter if value is 3 or more characters
  if (value && value.length < 3) {
    return 'invalid.length';
  }
  return undefined;
}

export const EventsFilters = () => {
  const translate = useTranslate();
  return [
    <TextInput
      key="eventReference"
      source="eventReference"
      alwaysOn={true}
      resettable={true}
      label={translate('eventsPanel.eventReference')}
    />,
    <TextInput
      key="deviceReference"
      source="device.reference"
      name="deviceReference"
      alwaysOn={true}
      resettable={true}
      label={translate('eventsPanel.deviceReference')}
      validate={filterDeviceReference}
    />,
    <TextInput
      key="eventSource"
      source="source"
      alwaysOn={true}
      resettable={true}
      label={translate('eventsPanel.eventSource')}
    />,
    <TextInput
      key="eventCode"
      source="code"
      alwaysOn={true}
      resettable={true}
      label={translate('eventsPanel.eventCode')}
    />,
  ];
};
