const jwt = require('jsonwebtoken');

/**
 * Verify a JWT and returns its decoded payload
 * Should switch to a verify instead of decode once public/private keys pair is  used to generate tokens
 * @param {string} token
 * @returns {Promise<{user: {familyName: string, givenName: string, email: string, picture: string | null}, thirdPartyId: string, provider: string, iat: number, exp: number}>}
 */
function decodeToken(token) {
  const decoded = jwt.decode(token);
  if (!decoded) {
    throw Error();
  }
  return decoded;
}

export { decodeToken };
