import { stringify } from 'query-string';

import { fetchUtils } from 'react-admin';

import authProvider from './authProvider';
import { Resource } from '../utils/enums';

const apiUrl = process.env.REACT_APP_NRJ_ENDPOINT;

const httpClient = async(url, options = {}) => {
  options.user = {
    authenticated: true,
    token: `Bearer ${await authProvider.checkAuth()}`,
  };
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  try {
    const res = await fetchUtils.fetchJson(url, options);
    return res;
  } catch (error) {
    if (Array.isArray(error.message)) {
      error.message = error.message.join(', ');
    }

    throw error;
  }
};

const nrjDataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    // TODO find a better way to not call getList of groups when there is no company in query (response would be slowing down the app)
    if (Resource.groups === resource && !query.company) {
      return {
        data: [],
        total: 0,
      };
    }
    const res = httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.data.length,
    }));
    return res;
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json.data[0],
    })),

  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: (resource, params) => {
    const query = params.filter;
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.data.length,
    }));
  },
};

export default nrjDataProvider;
