import { Fragment, useState } from 'react';

import { Tab, Tabs } from '@mui/material';

import { useTranslate } from 'react-admin';

import { PayloadTab } from './payload-tab';
import { TopicTab } from './topic-tab';

export const EventPanel = ({ record }) => {
  const [tabValue, setTabValue] = useState(0);
  const translate = useTranslate();

  return (
    <Fragment>
      <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
        <Tab label={translate('eventsTabs.payload.name')} />
        <Tab label={translate('eventsTabs.topic.name')} />
        {/* Add more tabs here later */}
      </Tabs>
      <Fragment>
        {tabValue === 0 && <PayloadTab record={record} />}
        {tabValue === 1 && <TopicTab record={record} />}
        {/* Add more tab content here later */}
      </Fragment>
    </Fragment>
  );
};
