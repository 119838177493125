import * as React from 'react';
import { useState } from 'react';

import {
  Apps,
  AutorenewOutlined,
  Cached,
  EmojiFlags,
  ListAlt,
  OfflineBolt,
  Receipt,
  SettingsInputComposite,
  SupervisedUserCircle,
  TourRounded,
  Web,
} from '@mui/icons-material';

import { DashboardMenuItem, Layout, MenuItemLink, useSidebarState, useTranslate } from 'react-admin';

import { useStyles } from './MenuLayout.style';
import { SubMenu } from './SubMenu';

export const MyLayout = () => <Layout menu={MenuLayout} />;

export const MenuLayout = () => {
  const [state, setState] = useState({
    menuBsm: true,
    menuNrj: true,
    menuIot: true,
    menuFF: true,
  });
  const [isSidebarOpen] = useSidebarState();

  const handleToggle = (menu) => setState((state) => ({ ...state, [menu]: !state[menu] }));

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={(classes.menu, !isSidebarOpen ? classes.menuClosed : undefined)}>
      {' '}
      <DashboardMenuItem />
      {/* MENU BSM */}
      <SubMenu title="BSM" icon={<Receipt />} handleToggle={() => handleToggle('menuBsm')} isMenuOpen={state.menuBsm}>
        <MenuItemLink
          to={'/connectors'}
          primaryText={translate('menu.connectors')}
          leftIcon={<SettingsInputComposite />}
        />
        <MenuItemLink to={'/providers'} primaryText={translate('menu.providers')} leftIcon={<Web />} />
        <MenuItemLink
          to={'/customer-areas'}
          primaryText={translate('menu.customerAreas')}
          leftIcon={<SupervisedUserCircle />}
        />
        <MenuItemLink to={'/synchronizations'} primaryText={translate('menu.synchronizations')} leftIcon={<Cached />} />
      </SubMenu>
      {/* MENU NRJ */}
      <SubMenu
        title="NRJ"
        icon={<OfflineBolt />}
        handleToggle={() => handleToggle('menuNrj')}
        isMenuOpen={state.menuNrj}>
        <MenuItemLink
          to={'/internal-imports'}
          primaryText={translate('menu.internalImportsHistory')}
          leftIcon={<ListAlt />}
        />
      </SubMenu>
      {/* MENU IOT */}
      <SubMenu
        title="IOT"
        icon={<AutorenewOutlined />}
        handleToggle={() => handleToggle('menuIot')}
        isMenuOpen={state.menuIot}>
        <MenuItemLink to={'/dataSource/'} primaryText={translate('menu.sources')} leftIcon={<ListAlt />} />
        <MenuItemLink
          to={'/externalContractors/'}
          primaryText={translate('menu.externalContractors')}
          leftIcon={<ListAlt />}
        />
        <MenuItemLink to={'/events/'} primaryText={translate('menu.events')} leftIcon={<ListAlt />} />
      </SubMenu>
      {/* MENU FeatureFlago */}
      <SubMenu
        title="FeatureFlago"
        icon={<EmojiFlags />}
        handleToggle={() => handleToggle('menuFeatureFlago')}
        isMenuOpen={state.menuFF}>
        <MenuItemLink to={'/flags/'} primaryText={translate('menu.flags')} leftIcon={<TourRounded />} />
        <MenuItemLink to={'/applications/'} primaryText={translate('menu.applications')} leftIcon={<Apps />} />
      </SubMenu>
    </div>
  );
};
