import { Fragment } from 'react';

import { useWatch } from 'react-hook-form';

import { SelectInput, required, useGetOne, useTranslate } from 'react-admin';

import { Resource } from '../../../utils/enums';

const formatFrequencyChoice = (f) => ({
  id: f,
  name: `resources.customer-areas.frequencies.${f}.long`,
});
const FREQUENCIES_CHOICES = [
  'inherited',
  'deactivated',
  'every_day',
  'every_month',
  'every_two_months',
  'every_three_months',
  'every_six_months',
  'twice_a_month',
  'nc',
].map(formatFrequencyChoice);

export const RetrieveFrequencyInput = ({ ...props }) => {
  const translate = useTranslate();
  const providerId = useWatch({
    name: 'providerId',
  });
  const { data: provider } = useGetOne(
    Resource.providers,
    { id: providerId },
    {
      enabled: Boolean(providerId),
    }
  );

  const providerFrequency = provider && formatFrequencyChoice(provider.retrieveFrequency);

  // eslint-disable-next-line no-unused-vars
  const formatOptionText = ({ id, name }) =>
    translate(name, {
      value: providerFrequency ? translate(providerFrequency.name) : 'NC',
    });

  return (
    <Fragment>
      <SelectInput
        {...props}
        validate={required()}
        optionText={formatOptionText}
        choices={FREQUENCIES_CHOICES}
        defaultValue={FREQUENCIES_CHOICES[0].id}
      />
    </Fragment>
  );
};
