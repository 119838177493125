import * as React from 'react';

import {
  ArrayField,
  AutocompleteArrayInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  UrlField,
  maxLength,
  required,
  useTranslate,
} from 'react-admin';

import { useStyles } from './ExternalContractorPage.style';
import { FieldList } from '../../components/common';
import { EmailsInput } from '../../components/IOT';
import { ApiInputs } from '../../components/IOT/ExternalContractors/ApiInputs';
import { Resource } from '../../utils/enums';

export const ExternalContractorList = (props) => {
  const classes = useStyles();

  return (
    <List sort={{ field: 'name', order: 'ASC' }} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceArrayField reference={Resource.customers} source="customers" className={classes.chipList} />
        <UrlField source="url" />
        <span label="Api Key">******</span>
        <ArrayField source="emails">
          <FieldList field="emails" />
        </ArrayField>
        <TextField source="projectManager" />
        <TextField source="comment" />
        <ReferenceField reference={Resource.sources} source="source" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const ExternalContractorCreate = (props) => {
  const translate = useTranslate();
  const requiredValidator = required({
    message: translate('validation.required'),
  });

  return (
    <Create {...props} redirect="list">
      <SimpleForm mode="onBlur">
        <TextInput source="name" validate={[requiredValidator]} />
        <ReferenceArrayInput source="customers" reference={Resource.customers}>
          <AutocompleteArrayInput debounce={500} validate={requiredValidator} />
        </ReferenceArrayInput>
        <ApiInputs />
        <EmailsInput />
        <TextInput source="projectManager" />
        <TextInput source="comment" validate={maxLength(280, translate('validation.length'))} />
        <ReferenceInput source="source" reference={Resource.sources} />
      </SimpleForm>
    </Create>
  );
};

export const ExternalContractorEdit = (props) => {
  const translate = useTranslate();
  const requiredValidator = required({
    message: translate('validation.required'),
  });
  const removeUnchangedApiKey = (data, { previousData }) => {
    if (data.apiKey === previousData.apiKey) {
      delete data.apiKey;
    }
    return data;
  };

  return (
    <Edit {...props} mutationMode="pessimistic" transform={removeUnchangedApiKey}>
      <SimpleForm mode="onChange">
        <TextInput disabled source="id" />
        <TextInput source="name" validate={[requiredValidator]} />
        <ReferenceArrayInput source="customers" reference={Resource.customers}>
          <AutocompleteArrayInput debounce={500} validate={requiredValidator} />
        </ReferenceArrayInput>
        <ApiInputs isEdit />
        <EmailsInput />
        <TextInput source="projectManager" />
        <TextInput source="comment" validate={maxLength(280, translate('validation.length'))} />
        <ReferenceInput source="source" reference={Resource.sources} />
      </SimpleForm>
    </Edit>
  );
};
