import { AuthenticationError, InvalidSessionError, UnauthenticatedError } from './errors';
import { jwtHelper } from '../utils/helpers';

const TOKEN_STORAGE_KEY = 'token';
const USER_STORAGE_KEY = 'user';

const authProvider = {
  /**
   * Called when the user attempts to log in
   * @param {string} token - token to store in local storage
   * @returns {Promise<void>}
   */
  login: async(token) => {
    try {
      const { user } = jwtHelper.decodeToken(token);
      localStorage.setItem(TOKEN_STORAGE_KEY, token);
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    } catch (error) {
      throw new AuthenticationError();
    }

    return;
  },

  /**
   * Called when the user clicks on the logout button
   * @returns {Promise<void>}
   */
  logout: async() => {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem(USER_STORAGE_KEY);

    return;
  },

  /**
   * Called when the API returns an error
   * @param {object} res - response
   * @param {number} res.status - status code of the response
   * @returns {Promise<void>}
   */
  checkError: async({ status }) => {
    if (status === 401) {
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      localStorage.removeItem(USER_STORAGE_KEY);
      throw new InvalidSessionError();
    }

    return;
  },

  /**
   * Called when the user navigates to a new location, to check for authentication
   * @returns {Promise<string>}
   */
  checkAuth: async() => {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (!token) {
      throw new UnauthenticatedError();
    }
    // Check if valid token
    try {
      jwtHelper.decodeToken(token);
    } catch (error) {
      throw new InvalidSessionError();
    }

    return token;
  },

  /**
   * Called when the user navigates to a new location, to check for permissions / roles
   * For now, logged in users have all rights on the backoffice
   /**
    * @returns {Promise<void>}
    */
  getPermissions: async() => undefined,

  /**
   * Called when we want to retrieve user's information from the local storage
   * @returns {Promise<id: string, fullName: string, avatar: string>} - The expected fields for react admin
   */
  getIdentity: async() => {
    const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
    const id = user.email;
    const fullName = `${user.givenName} ${user.familyName}`;
    const avatar = user.picture;

    return { id, fullName, avatar };
  },
};

export default authProvider;
