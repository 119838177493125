import { ArrayField, ChipField, Datagrid, DateField, SimpleShowLayout, TextField, useTranslate } from 'react-admin';

import { LogsPanel } from './LogsPanel';

export const JournalTab = () => {
  const translate = useTranslate();

  return (
    <SimpleShowLayout>
      <ArrayField source="jobs">
        <Datagrid expand={<LogsPanel />} rowClick="expand" bulkActionButtons={false}>
          <TextField source="jobId" label={translate('syncTabs.journal.fields.jobId')} />
          <DateField showTime={true} source="requestedAt" label={translate('syncTabs.journal.fields.requestedAt')} />
          <ChipField source="state" label={translate('syncTabs.journal.fields.state')} />
          <ChipField source="collectStatus" label={translate('syncTabs.journal.fields.collectStatus')} />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};
