import * as React from 'react';

import { Create, SimpleForm, TextInput, List, Datagrid, TextField, Edit, EditButton } from 'react-admin';

export const SourcesList = (props) => (
  <List sort={{ field: 'name', order: 'ASC' }} {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="value" />
      <EditButton />
    </Datagrid>
  </List>
);

export const SourcesCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="value" />
    </SimpleForm>
  </Create>
);

export const SourcesEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput source="value" />
    </SimpleForm>
  </Edit>
);
