import PropTypes from 'prop-types';

import { BooleanInput, useTranslate } from 'react-admin';

/**
 * Returns option fields from given configs.
 * The config should contains the componentName (BooleanInput...), the label, the default value.
 * If the componentName is not found, throw an error.
 */
export const OptionFields = ({ importType, optionFields }) => {
  const translate = useTranslate();

  return optionFields.map(({ componentName, labelKey, source, defaultValue }) => {
    if (componentName === 'BooleanInput') {
      return (
        <BooleanInput
          key={source}
          source={source}
          label={translate(`resources.internal-imports.options.${importType}.${labelKey}`)}
          defaultValue={defaultValue}
        />
      );
    }

    throw new Error(`Unknown component name ${componentName}`);
  });
};

OptionFields.propTypes = {
  optionFields: PropTypes.arrayOf(PropTypes.object),
};
