import * as React from 'react';

import { Card, CardContent, CardHeader } from '@mui/material';

const dashboard = () => (
  <Card>
    <CardHeader title="Welcome to the administration" />
    <CardContent>Lorem ipsum sic dolor amet...</CardContent>
  </Card>
);

export default dashboard;
