import { Fragment } from 'react';

import { useWatch } from 'react-hook-form';

import { BooleanInput, useGetOne, useTranslate } from 'react-admin';

import { Resource } from '../../../utils/enums';

export const IsEnabledInput = ({ ...props }) => {
  const translate = useTranslate();
  const providerId = useWatch({
    name: 'providerId',
  });
  const { data: provider } = useGetOne(
    Resource.providers,
    { id: providerId },
    {
      enabled: Boolean(providerId),
    }
  );

  const hasConnector = Boolean(provider && provider.connectorId);

  return (
    <Fragment>
      <BooleanInput
        {...props}
        disabled={!hasConnector}
        defaultValue={hasConnector}
        helperText={hasConnector ? null : translate('resources.customer-areas.helpers.enabled')}
      />
    </Fragment>
  );
};
