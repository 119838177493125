import { jsonHttpClient } from '../../services/dataProvider';
import { Resource } from '../enums';

/** Fetch signed URL from file storage id */
export async function fetchSignedUrl(fileId) {
  const url = `${process.env.REACT_APP_NRJ_ENDPOINT}/${Resource.fileStorages}/url/${fileId}`;
  const options = { method: 'GET' };

  const res = await jsonHttpClient(url, options);
  const fileUrl = JSON.parse(res.body).data;
  return fileUrl;
}
