import * as React from 'react';

import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateInput,
  Edit,
  EditButton,
  FunctionField,
  List,
  PasswordInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  email,
  regex,
  required,
  useEditController,
  useGetList,
  useTranslate,
} from 'react-admin';

import {
  ClientInput,
  IsEnabledInput,
  RetrieveFrequencyInput,
  RetrieveFrequencyShortField,
  customerAreasFilters,
} from '../../components/BillSyncManager';
import { Resource } from '../../utils/enums';

export const CustomerAreasList = (props) => {
  const translate = useTranslate();

  return (
    <List filters={customerAreasFilters} {...props}>
      <Datagrid rowClick="toggleSelection">
        <TextField source="name" />
        <ReferenceField source="clientId" reference={Resource.companies}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="providerId" reference={Resource.providers}>
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="enabled" />
        <FunctionField
          label={translate('resources.customer-areas.fields.synchronizations')}
          render={(record) => (record.synchronizations ? record.synchronizations.length : 0)}
          sortable={false}
        />
        <RetrieveFrequencyShortField label={translate('resources.customer-areas.fields.frequency')} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const CustomerAreasEdit = (props) => {
  let providers = [];
  const translate = useTranslate();
  const refereeEmailValidator = [
    required(),
    email(translate('resources.customer-areas.validators.refereeMail')),
    regex(/@citron.io$/, translate('resources.customer-areas.validators.refereeMail')),
  ];

  const { record } = useEditController(props);

  // Returns a boolean to signal if the frequency should disable the DateInput
  const isDisabled = (freq) => freq === 'nc' || freq === 'deactivated';
  // Returns the frequency for a given provider
  const inheritedFrequency = (providerId) => providers.find(({ id }) => id === providerId)?.retrieveFrequency;

  let [isFrequencyDisabled, setIsFrequencyDisabled] = React.useState(null);
  const [retrieveFrequency] = React.useState(record?.retrieveFrequency);
  const [providerId, setProviderId] = React.useState(record?.providerId);

  // Used by the OnChange of the frequency input
  const handleFrequencyChange = ({ target }) => {
    // Uses either a new provider selected through handleProviderChange
    // or the orginal provider when loading the page
    const provider = providerId || record.providerId;

    let newFreq = target?.value;
    if (target.value === 'inherited' && provider) {
      newFreq = inheritedFrequency(provider);
    }
    setIsFrequencyDisabled(isDisabled(newFreq));
  };

  // Used by the OnChange event
  const handleProviderChange = (value) => {
    // Updates the reference to the providerId
    setProviderId(value);

    if (retrieveFrequency === 'inherited' || (!retrieveFrequency && record.retrieveFrequency === 'inherited')) {
      // Re-evaluates if the inherited frequency is NC to disabled id necessary
      setIsFrequencyDisabled(isDisabled(inheritedFrequency(value)));
    } else {
      setIsFrequencyDisabled(isDisabled(retrieveFrequency || record.retrieveFrequency));
    }
  };

  // Retrieves all providers once to help get the different frequencies
  const { data } = useGetList(Resource.providers);
  if (data) {
    providers = Object.values(data);
  }

  // Waits for all the data to load to render the compoenents
  if (record === undefined || data === undefined) {
    return <>Still loading...</>;
  }

  // This helps disable the input if when init the form there is no isFrequencyDisabled
  // If any of the relatated input is modified this isn't called anymore
  if (record && data && !providerId && isFrequencyDisabled === null) {
    isFrequencyDisabled = record.nextAutoSyncBeyond === null || isDisabled(record.retrieveFrequency);
  }

  // We disable the nextAutoSyncBeyond input if there never was a synchronization
  const wasSynched = !record?.lastAutoSyncEndDate;

  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm sanitizeEmptyValues={true}>
        <TextInput disabled source="id" />
        <TextInput source="name" validate={required()} />
        <TextInput
          source="referee"
          label={translate('resources.customer-areas.fields.referee')}
          validate={refereeEmailValidator}
          type="email"
          resettable
        />
        <ClientInput disabled />
        <ReferenceInput
          source="providerId"
          reference={Resource.providers}
          validate={required()}
          onChange={handleProviderChange}>
          <AutocompleteInput filterToQuery={(searchText) => (searchText ? { name: searchText } : {})} debounce={500} />
        </ReferenceInput>
        <TextInput
          source="credentials.login"
          label={translate('resources.customer-areas.fields.login')}
          validate={required()}
        />
        <PasswordInput
          source="credentials.password"
          label={translate('resources.customer-areas.fields.password')}
          validate={required()}
        />
        <DateInput
          source="minRetrieveDate"
          label={translate('resources.customer-areas.fields.minRetrieveDate')}
          helperText={translate('messages.inactiveFeature')}
          format={(v) => v}
          parse={(v) => v || null}
        />
        <RetrieveFrequencyInput
          source="retrieveFrequency"
          label={translate('resources.customer-areas.fields.retrieveFrequency')}
          onChange={handleFrequencyChange}
        />
        <DateInput
          source="nextAutoSyncBeyond"
          label={translate('resources.customer-areas.fields.nextAutoSyncBeyond')}
          format={(value) => {
            if (!value || wasSynched || isFrequencyDisabled) return null;
            const date = new Date(value);
            const data = isNaN(date) ? null : date.toISOString().slice(0, 10);
            return data;
          }}
          parse={(v) => v || null}
          disabled={wasSynched || isFrequencyDisabled}
        />
        <BooleanInput
          source="ignoreExistingGroup"
          label={translate('resources.customer-areas.fields.ignoreExistingGroup')}
        />
        <IsEnabledInput source="enabled" label={translate('resources.customer-areas.fields.enabled')} />
      </SimpleForm>
    </Edit>
  );
};

export const CustomerAreasCreate = (props) => {
  const translate = useTranslate();
  const refereeEmailValidator = [
    required(),
    email(translate('resources.customer-areas.validators.refereeMail')),
    regex(/@citron.io$/, translate('resources.customer-areas.validators.refereeMail')),
  ];

  return (
    <Create {...props} mutationMode="pessimistic" redirect="list">
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput
          source="referee"
          label={translate('resources.customer-areas.fields.referee')}
          validate={refereeEmailValidator}
          type="email"
          resettable
        />
        <ClientInput />
        <ReferenceInput source="providerId" reference={Resource.providers} validate={required()} resettable="true">
          <AutocompleteInput filterToQuery={(searchText) => (searchText ? { name: searchText } : {})} debounce={500} />
        </ReferenceInput>
        <TextInput
          source="credentials.login"
          label={translate('resources.customer-areas.fields.login')}
          validate={required()}
        />
        <PasswordInput
          source="credentials.password"
          label={translate('resources.customer-areas.fields.password')}
          validate={required()}
        />
        <DateInput
          source="minRetrieveDate"
          allowEmpty
          label={translate('resources.customer-areas.fields.minRetrieveDate')}
          helperText={translate('messages.inactiveFeature')}
          format={(v) => v}
          parse={(v) => v || null}
        />
        <RetrieveFrequencyInput
          source="retrieveFrequency"
          label={translate('resources.customer-areas.fields.retrieveFrequency')}
        />
        <BooleanInput
          source="ignoreExistingGroup"
          defaultValue={true}
          label={translate('resources.customer-areas.fields.ignoreExistingGroup')}
        />
        <IsEnabledInput source="enabled" label={translate('resources.customer-areas.fields.enabled')} />
      </SimpleForm>
    </Create>
  );
};
