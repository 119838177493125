import { DateField, FunctionField, RichTextField, SimpleShowLayout, TextField, useTranslate } from 'react-admin';

export const SummaryTab = () => {
  const translate = useTranslate();

  return (
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="uuid" label={translate('syncTabs.summary.fields.uuid')} />
      <TextField source="type" label={translate('syncTabs.summary.fields.type')} />
      <TextField source="createdBy" label={translate('syncTabs.summary.fields.createdBy')} />
      <TextField source="updatedBy" label={translate('syncTabs.summary.fields.updatedBy')} />
      <FunctionField
        source="files"
        label={translate('syncTabs.summary.fields.files')}
        render={(record) => record.jobs.reduce((total, job) => total + job.nbFilesCollected + job.nbFilesSkipped, 0)}
      />
      <FunctionField
        source="retry"
        label={translate('syncTabs.summary.fields.retry')}
        // Fix display since there is n-1 retries for n attempts
        // Therefore, we display the number of jobs
        render={(record) => record.jobs.length}
      />
      <DateField showTime={true} source="requestedAt" label={translate('syncTabs.summary.fields.requestedAt')} />
      <DateField showTime={true} source="importedAt" label={translate('syncTabs.summary.fields.importedAt')} />
      <RichTextField disabled source="report" label={translate('syncTabs.summary.fields.report')} />
    </SimpleShowLayout>
  );
};
