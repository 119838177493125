import { ArrayField, ChipField, Datagrid, DateField, SimpleShowLayout, TextField, useTranslate } from 'react-admin';

import { FilesPanel } from './FilesPanel';

export const JobsTab = () => {
  const translate = useTranslate();

  return (
    <SimpleShowLayout>
      <ArrayField source="jobs">
        <Datagrid expand={<FilesPanel />} rowClick="expand" bulkActionButtons={false}>
          <TextField source="jobId" label={translate('syncTabs.jobs.fields.jobId')} />
          <TextField source="launchedBy" label={translate('syncTabs.jobs.fields.launchedBy')} />
          <DateField showTime={true} source="requestedAt" label={translate('syncTabs.jobs.fields.requestedAt')} />
          <DateField showTime={true} source="importedAt" label={translate('syncTabs.jobs.fields.importedAt')} />
          <ChipField source="state" label={translate('syncTabs.jobs.fields.state')} />
          <ChipField source="collectStatus" label={translate('syncTabs.jobs.fields.collectStatus')} />
          <TextField source="errorCode" label={translate('syncTabs.jobs.fields.errorCode')} />
          <TextField label={translate('syncTabs.jobs.fields.collectedFiles')} source="nbFilesCollected" />
          <TextField label={translate('syncTabs.jobs.fields.skippedFiles')} source="nbFilesSkipped" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};
