import PropTypes from 'prop-types';

import { AutocompleteInput, ReferenceInput, useGetOne, useTranslate } from 'react-admin';

import { Resource } from '../../../utils/enums/';

export const GroupInput = (props) => {
  const translate = useTranslate();
  const { data } = useGetOne(
    Resource.customerAreas,
    { id: props.formData.customerAreaId },
    { enabled: props.formData.customerAreaId ? true : false }
  );
  if (data) {
    return (
      <ReferenceInput
        source="groupId"
        label={translate('resources.synchronizations.fields.groupId')}
        reference={Resource.groups}
        filter={{ company: data.clientId }}
        sort={{ field: 'name', order: 'ASC' }}>
        <AutocompleteInput filterToQuery={(searchText) => (searchText ? { name: searchText } : {})} debounce={500} />
      </ReferenceInput>
    );
  }
  return (
    <ReferenceInput
      disabled
      source="groupId"
      label={translate('resources.synchronizations.fields.groupId')}
      reference={Resource.groups}
      sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput filterToQuery={(searchText) => (searchText ? { name: searchText } : {})} debounce={500} />
    </ReferenceInput>
  );
};

GroupInput.propTypes = {
  formData: PropTypes.object,
};
