import { Fragment, useState } from 'react';

import { Tab, Tabs } from '@mui/material';

import { Show, useTranslate } from 'react-admin';

import { JournalTab } from './JournalTab';
import { SummaryTab } from './SummaryTab';

export const InternalImportPanel = (props) => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const translate = useTranslate();

  return (
    <Fragment>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label={translate('internalImportsTab.summary.name')} />
        <Tab label={translate('internalImportsTab.journal.name')} />
      </Tabs>
      <Show actions={null} {...props} title=" ">
        <Fragment>
          {tabValue === 0 && <SummaryTab />}
          {tabValue === 1 && <JournalTab />}
        </Fragment>
      </Show>
    </Fragment>
  );
};
