import jsonServerProvider from 'ra-data-json-server';

import { fetchUtils } from 'react-admin';

import authProvider from './authProvider';
import nrjDataProvider from './dataProviderNRJ';
import { Resource } from '../utils/enums';

/**
 * Http client used when requesting a citron client using our generated JWT
 * @param {string} url
 * @param {object} options
 * @returns {Promise<{status: number, headers: Headers, body: string, json: any}>}
 */
export const basicHttpClient = async(url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${await authProvider.checkAuth()}`,
    });
  }

  try {
    const res = await fetch(url, options);
    return res;
  } catch (error) {
    if (Array.isArray(error.message)) {
      error.message = error.message.join(', ');
    }

    throw error;
  }
};

/**
 * Http client used when requesting a citron client using our generated JWT
 * @param {string} url
 * @param {object} options
 * @returns {Promise<{status: number, headers: Headers, body: string, json: any}>}
 */
export const jsonHttpClient = async(url, options = {}) => {
  options.user = {
    authenticated: true,
    token: `Bearer ${await authProvider.checkAuth()}`,
  };
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  try {
    const res = await fetchUtils.fetchJson(url, options);
    return res;
  } catch (error) {
    if (Array.isArray(error.message)) {
      error.message = error.message.join(', ');
    }

    throw error;
  }
};

/** List of available data providers and the managed resources */
const dataProviders = [
  {
    dataProvider: jsonServerProvider(process.env.REACT_APP_BSM_ENDPOINT, jsonHttpClient),
    resources: [
      Resource.connectors,
      Resource.providers,
      Resource.customerAreas,
      Resource.synchronizations,
      Resource.synchronizationFiles,
    ],
  },
  {
    dataProvider: nrjDataProvider,
    resources: [Resource.companies, Resource.groups],
  },
  {
    dataProvider: jsonServerProvider(process.env.REACT_APP_NRJ_ENDPOINT, jsonHttpClient),
    resources: [Resource.internalImports],
  },
  {
    dataProvider: jsonServerProvider(process.env.REACT_APP_IOT_ENDPOINT, jsonHttpClient),
    resources: [Resource.sources, Resource.externalContractors, Resource.customers, Resource.events],
  },
  {
    dataProvider: jsonServerProvider(process.env.REACT_APP_FEATURE_FLAG_ENDPOINT, jsonHttpClient),
    resources: [Resource.flags, Resource.applications],
  },
];

const getDataProvider = (resource) => {
  const dataProviderMapping = dataProviders.find((dp) => dp.resources.includes(resource));
  return dataProviderMapping.dataProvider;
};

// In React-Admin, items MUST have "id" field. It can't be changed to a custom field.
const dataProvider = {
  getList: async(resource, params) => {
    const provider = getDataProvider(resource);
    const res = await provider.getList(resource, params);
    res.data.forEach((x) => (x.id = x.id || x._id));
    return res;
  },
  getOne: async(resource, params) => {
    const provider = getDataProvider(resource);
    const res = await provider.getOne(resource, params);
    res.data.id = res.data.id || res.data._id;
    return res;
  },
  getMany: async(resource, params) => {
    const provider = getDataProvider(resource);
    const res = await provider.getMany(resource, params);
    res.data.forEach((x) => (x.id = x.id || x._id));
    return res;
  },
  getManyReference: async(resource, params) => {
    const provider = getDataProvider(resource);
    const res = await provider.getManyReference(resource, params);
    res.data.forEach((x) => (x.id = x.id || x._id));
    return res;
  },
  create: async(resource, params) => {
    const provider = getDataProvider(resource);
    const res = await provider.create(resource, params);
    res.data.id = res.data.id || res.data._id;
    return res;
  },
  // eslint-disable-next-line id-denylist
  update: async(resource, params) => {
    const provider = getDataProvider(resource);
    const res = await provider.update(resource, params);
    res.data.id = res.data.id || res.data._id;
    return res;
  },
  updateMany: async(resource, params) => {
    const provider = getDataProvider(resource);
    const res = await provider.updateMany(resource, params);
    res.data.forEach((x) => (x.id = x.id || x._id));
    return res;
  },
  // eslint-disable-next-line id-denylist
  delete: (resource, params) => {
    const provider = getDataProvider(resource);
    return provider.delete(resource, params);
  },
  deleteMany: (resource, params) => {
    const provider = getDataProvider(resource);
    return provider.deleteMany(resource, params);
  },
};

export default dataProvider;
