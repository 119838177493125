import React from 'react';

import { useParams } from 'react-router-dom';

import { useLogin, useNotify } from 'react-admin';

import { AuthenticationError } from '../../services/errors';

const SuccessPage = () => {
  const login = useLogin();
  const { jwt } = useParams();
  const notify = useNotify();

  /** Called when trying to save a new token */
  const saveTokenAction = () => {
    login(jwt).catch((error) => {
      if (error instanceof Error && error.message) notify(error.message, 'error');
      else notify(new AuthenticationError().message, 'error');
    });
  };

  /** If we have received a jwt */
  if (jwt) {
    saveTokenAction();
  }

  return <div>You are logged in</div>;
};

export default SuccessPage;
