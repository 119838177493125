import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  NumberField,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

export const ApplicationsList = (_props) => (
  <List>
    <Datagrid rowClick="toggleSelection">
      <NumberField source="id" disabled />
      <TextField source="label" />
      <TextField source="description" />
      <DateField showTime={true} source="createdAt" />
      <DateField showTime={true} source="updatedAt" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ApplicationsEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <NumberField source="id" disabled />
      <TextInput source="label" />
      <TextInput source="description" />
    </SimpleForm>
  </Edit>
);

export const ApplicationsCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="label" validate={required()} />
      <TextInput source="description" validate={required()} />
    </SimpleForm>
  </Create>
);
