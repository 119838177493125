import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';

import { FunctionField, SimpleShowLayout, useNotify, useTranslate } from 'react-admin';

import { jsonHttpClient } from '../../../services/dataProvider';
import { Resource } from '../../../utils/enums';

export const TopicTab = ({ record }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [topics, setTopics] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(async() => {
    try {
      setLoading(true);
      const response = await jsonHttpClient(
        `${process.env.REACT_APP_IOT_ENDPOINT}/${Resource.events}/${record.id}/topics`,
        {
          method: 'GET',
        }
      );
      setTopics(response.json);
    } catch (error) {
      notify(translate('eventsTabs.topic.errorFetchingTopics'), { type: 'error' });
    } finally {
      setLoading(false);
    }
  }, [record.id, notify]);

  const renderItem = (item) => (
    <div
      key={item._id}
      style={{
        padding: '8px',
        marginBottom: '8px',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
      }}>
      <div>
        <strong>{translate('eventsTabs.topic.fields.name')}:</strong> {item.name}
      </div>
      <div>
        <strong>{translate('eventsTabs.topic.fields.status')}:</strong> {item.status}
      </div>
      <div>
        <strong>{translate('eventsTabs.topic.fields.step')}:</strong> {item.step}
      </div>
    </div>
  );

  return (
    <SimpleShowLayout>
      <div style={{ width: '100%', fontSize: '1rem', fontWeight: 'bold' }}>{translate('eventsTabs.topic.info')}</div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
          <CircularProgress />
        </div>
      ) : (
        <FunctionField
          label={false}
          render={() => {
            if (!topics || topics.length === 0) {
              return translate('eventsTabs.topic.noTopic');
            }
            return <div style={{ maxWidth: '100%', overflowX: 'auto' }}>{topics.map((item) => renderItem(item))}</div>;
          }}
        />
      )}
    </SimpleShowLayout>
  );
};
