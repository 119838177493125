import * as React from 'react';

import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

const connectorFilters = [<TextInput key="name" source="name" alwaysOn={true} resettable={true} />];

export const ConnectorList = (props) => (
  <List filters={connectorFilters} {...props}>
    <Datagrid rowClick="toggleSelection">
      <TextField source="name" />
      <BooleanField source="enabled" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ConnectorEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const ConnectorCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <BooleanInput source="enabled" defaultValue={true} />
    </SimpleForm>
  </Create>
);
