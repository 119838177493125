import * as React from 'react';

import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useGetOne, useRecordContext, useTranslate } from 'react-admin';

import { Resource } from '../../../utils/enums';

/** Retrieve frequency in short format and with tooltip for long format */
export const RetrieveFrequencyShortField = (props) => {
  const record = useRecordContext(props);
  const translate = useTranslate();
  const { retrieveFrequency } = record;
  const { providerId } = record;
  const { data: provider } = useGetOne(
    Resource.providers,
    { id: providerId },
    {
      enabled: Boolean(providerId),
    }
  );

  /** Provider inherited frequency in short format */
  const providerFreqShort =
    provider && translate(`resources.customer-areas.frequencies.${provider.retrieveFrequency}.short`);
  /** Customer area frequency in short format */
  const areaFreqShort = translate(`resources.customer-areas.frequencies.${retrieveFrequency}.short`, {
    value: providerFreqShort || 'NC',
  });

  /** Provider inherited frequency in long format */
  const providerFreqLong = translate(
    `resources.customer-areas.frequencies.${provider?.retrieveFrequency || 'nc'}.long`
  );
  /** Customer area frequency in long format with needed params */
  const areaFreqLong = translate(`resources.customer-areas.frequencies.${retrieveFrequency}.long`, {
    value: providerFreqLong || 'NC',
  });

  return (
    <Tooltip title={areaFreqLong}>
      <Typography component="span" variant="body2">
        {areaFreqShort}
      </Typography>
    </Tooltip>
  );
};

RetrieveFrequencyShortField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  className: PropTypes.string,
  emptyText: PropTypes.string,
};
