import { useEffect, useState } from 'react';

import { SelectInput, TextInput, useNotify, useTranslate } from 'react-admin';

import { jsonHttpClient } from '../../../services/dataProvider';
import { Resource } from '../../../utils/enums';

export const InternalImportsListFilters = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const [stateChoices, setStateChoices] = useState([]);

  /** Sets the state filter with a list of imports states */
  function setStateFilter(states) {
    setStateChoices(
      states.map((state) => ({
        id: state,
        name: state,
      }))
    );
  }

  /** Fetch all available states from NRJ */
  function fetchStates() {
    const url = `${process.env.REACT_APP_NRJ_ENDPOINT}/${Resource.internalImports}/states`;
    const options = { method: 'GET' };

    return jsonHttpClient(url, options);
  }

  useEffect(() => {
    fetchStates()
      .then((response) => setStateFilter(response.json))
      .catch(() => notify(translate('messages.fetchImportsStatesError'), 'error'));
  }, []); // To trigger only once

  return [
    <SelectInput
      key="state"
      source="state"
      alwaysOn={true}
      allowEmpty={false}
      resettable={true}
      choices={stateChoices}
    />,
    <TextInput key="createdBy" source="createdBy" alwaysOn={true} resettable={true} />,
    <TextInput key="uuid" source="uuid" alwaysOn={true} resettable={true} />,
  ];
};
