import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: theme.spacing(2),
  },
  menuClosed: {
    maxWidth: theme.spacing(5),
  },
}));
