import { cloneElement } from 'react';

import { Cached, Input } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button, DeleteButton, EditButton, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';

import { jsonHttpClient } from '../../../services/dataProvider';
import { Resource } from '../../../utils/enums';

const ACTIONS_CONFIGS = {
  launch: {
    label: 'actions.launch',
    logo: <Cached />,
    successMessage: 'messages.synchronizationLaunchSuccess',
    errorMessage: 'messages.synchronizationLaunchError',
    allowedStates: {
      CREATED: [],
      REQUEST_ERROR: [],
      IMPORT_ERROR: [],
      ERROR: [],
      NO_FILE_RETRIEVED: ['FAILED', 'DONE_WITH_SKIPPED'],
      IMPORTED: ['FAILED', 'DONE_WITH_SKIPPED'],
    },
  },
  import: {
    label: 'actions.import',
    logo: <Input />,
    successMessage: 'messages.importLaunchSuccess',
    errorMessage: 'messages.importLaunchError',
    allowedStates: { IMPORT_ERROR: [] },
  },
  edit: {
    component: <EditButton />,
    successMessage: 'messages.importLaunchSuccess',
    errorMessage: 'messages.importLaunchError',
    allowedStates: { CREATED: [] },
  },
  // eslint-disable-next-line id-denylist
  delete: {
    component: <DeleteButton />,
    successMessage: 'messages.importLaunchSuccess',
    errorMessage: 'messages.importLaunchError',
    allowedStates: { CREATED: [] },
  },
};

export const ActionButton = (props) => {
  const { action, ...rest } = props;
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const actionConfig = ACTIONS_CONFIGS[action];

  const allowedCollectStatus = actionConfig.allowedStates[record.state];
  const statusIncluded =
    Array.isArray(allowedCollectStatus) &&
    (allowedCollectStatus.length === 0 || allowedCollectStatus.includes(record.collectStatus));

  const enabled = allowedCollectStatus && statusIncluded;

  const handleClick = (error) => {
    error.stopPropagation();

    const url = `${process.env.REACT_APP_BSM_ENDPOINT}/${Resource.synchronizations}/${record.id}/action?action=${action}`;
    const options = { method: 'PUT' };
    jsonHttpClient(url, options)
      .then(() => {
        notify(translate(actionConfig.successMessage), 'success');
        refresh();
      })
      .catch(() => notify(translate(actionConfig.errorMessage), 'error'));
  };
  const stopPropagation = (error) => error.stopPropagation();

  // For a newly created synchronization, as long the complete object has not been retrieved
  if (!record.id) {
    return null;
  }

  return (
    <div onClick={stopPropagation}>
      {actionConfig.component ? (
        cloneElement(actionConfig.component, {
          disabled: !enabled,
          style: { width: '-webkit-fill-available' },
          ...rest,
        })
      ) : (
        <Button
          component={Link}
          disabled={!enabled}
          label={translate(actionConfig.label)}
          onClick={handleClick}
          style={{ width: '-webkit-fill-available' }}
          {...rest}>
          {actionConfig.logo}
        </Button>
      )}
    </div>
  );
};

ActionButton.propTypes = {
  record: PropTypes.object,
  action: PropTypes.oneOf(Object.keys(ACTIONS_CONFIGS)),
};
