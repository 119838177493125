import { useFormContext } from 'react-hook-form';

import { AutocompleteInput, ReferenceInput, required, useTranslate } from 'react-admin';

import { Resource } from '../../../utils/enums';

export const ClientInput = (props) => {
  const { setValue } = useFormContext();
  const translate = useTranslate();

  const handleClientChange = () => {
    setValue('groupId', undefined);
  };

  return (
    <ReferenceInput
      source="clientId"
      label={translate('resources.customer-areas.fields.clientId')}
      reference={Resource.companies}
      validate={required()}
      resettable="true"
      sort={{ field: 'name', order: 'ASC' }}
      onChange={handleClientChange}
      {...props}>
      <AutocompleteInput filterToQuery={(searchText) => (searchText ? { name: searchText } : {})} debounce={500} />
    </ReferenceInput>
  );
};
