import { Box, Card, CardContent } from '@mui/material';

import { Datagrid, FilterForm, FunctionField, ListBase, Pagination, TextField, useTranslate } from 'react-admin';

import { EventPanel } from './event-panel.js';
import { EventsFilters } from './events-filters.js';

const DEFAULT_SORT = { field: 'timestamp', order: 'DESC' };

const formatTimestamp = (record) => new Date(record.timestamp * 1000).toISOString();

const EventDataGrid = () => (
  <Datagrid rowClick="expand" expand={(record) => <EventPanel record={record} />} bulkActionButtons={false}>
    <TextField source="reference" label="eventsPanel.eventReference" sortable={false} />
    <TextField source="code" label="eventsPanel.eventCode" sortable={false} />
    <FunctionField source="timestamp" label="eventsPanel.eventDate" render={formatTimestamp} />
    <TextField source="createdAt" label="eventsPanel.eventCreatedAt" />
    <TextField source="source" label="eventsPanel.eventSource" sortable={false} />
    <TextField source="device.reference" label="eventsPanel.deviceReference" sortable={false} />
    <TextField source="device.name" label="eventsPanel.deviceName" sortable={false} />
  </Datagrid>
);

const InfoCard = () => {
  const translate = useTranslate();

  return (
    <Card sx={{ marginBottom: 2, marginTop: 2 }}>
      <CardContent>{translate('eventsPanel.info')}</CardContent>
    </Card>
  );
};

const ListContent = ({ filters }) => (
  <div>
    <FilterForm filters={filters} />
    <Box>
      <EventDataGrid />
    </Box>
  </div>
);

export const EventList = (props) => {
  const translate = useTranslate();

  return (
    <>
      <InfoCard translate={translate} />
      <ListBase sort={DEFAULT_SORT} {...props}>
        <ListContent filters={EventsFilters()} />
        <Pagination />
      </ListBase>
    </>
  );
};
