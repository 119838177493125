import { FunctionField, SimpleShowLayout, useTranslate } from 'react-admin';

export const PayloadTab = () => {
  const translate = useTranslate();
  return (
    <SimpleShowLayout>
      <div style={{ width: '100%', fontSize: '1rem', fontWeight: 'bold' }}>{translate('eventsTabs.payload.info')}</div>
      <FunctionField
        label={false}
        render={(record) => {
          try {
            const payload = record?.metadata?.payload;
            if (!payload) {
              return translate('eventsTabs.payload.noPayload');
            }
            return <pre>{JSON.stringify(JSON.parse(payload), null, 2)}</pre>;
          } catch (error) {
            return translate('eventsTabs.payload.errorFormattingJSON');
          }
        }}
      />
    </SimpleShowLayout>
  );
};
