import { required, AutocompleteInput, ReferenceInput, Filter } from 'react-admin';

import { Resource as ResourceName } from '../../../utils/enums/resource.enum';

export const FlagFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="application"
      reference={ResourceName.applications}
      resettable={true}
      sort={{ field: 'label', order: 'ASC' }}
      validate={required()}>
      <AutocompleteInput
        optionText="label"
        optionValue="label"
        filterToQuery={(searchText) => (searchText ? { name: searchText.label } : {})}
        debounce={500}
      />
    </ReferenceInput>
  </Filter>
);
