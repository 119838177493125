import { DateField, SimpleShowLayout, TextField, useTranslate } from 'react-admin';

export const SummaryTab = () => {
  const translate = useTranslate();

  return (
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="uuid" label={translate('internalImportsTab.summary.fields.uuid')} />
      <TextField source="createdBy" label={translate('internalImportsTab.summary.fields.createdBy')} />
      <DateField source="createdAt" label={translate('internalImportsTab.summary.fields.createdAt')} showTime={true} />
      <DateField
        source="executionStartedAt"
        label={translate('internalImportsTab.summary.fields.executionStartedAt')}
        showTime={true}
      />
      <DateField
        source="executionEndedAt"
        label={translate('internalImportsTab.summary.fields.executionEndedAt')}
        showTime={true}
      />
    </SimpleShowLayout>
  );
};
