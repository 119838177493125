import React from 'react';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GoogleButton from 'react-google-button';
import { useLocation } from 'react-router-dom';

import { Notification } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(20),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const location = useLocation();

  /** Called when clicking on Google button */
  const loginAction = () => {
    window.location.href = `${process.env.REACT_APP_BACKOFFICE_ENDPOINT}/auth/google`;
  };

  return (
    <Container component="main" className={classes.container}>
      <LockOutlinedIcon color="primary" />
      <Box m={3}>
        <GoogleButton label="Se connecter via Google" onClick={loginAction} />
      </Box>
      {location.pathname === '/login/failure' ? (
        <Typography color="error">Vous n&apos;avez pas les droits nécessaires</Typography>
      ) : null}
      <Notification />
    </Container>
  );
};

export default LoginPage;
