import frenchMessages from 'ra-language-french';

export const fr = {
  ...frenchMessages,
  eventsTabs: {
    payload: {
      name: 'Metadata',
      noPayload: "Aucun payload d'origine disponible",
      errorFormattingJSON: 'Erreur lors du formatage du payload',
      info: 'Données brutes envoyées par le partenaire',
      invalidFormat: 'Format de données invalide',
    },
    topic: {
      name: 'Topics',
      info: "Sujet(s) de flux d'actualité concerné par l'événement",
      noTopic: 'Aucun topic disponible',
      errorFetchingTopics: 'Erreur lors de la récupération des topics',
      fields: {
        name: 'Nom',
        status: 'Statut',
        step: 'Étape',
      },
    },
  },
  eventsPanel: {
    deviceName: "Nom de l'équipement",
    deviceReference: 'Référence équipement',
    eventCode: "Code de l'événement",
    eventCreatedAt: 'Date de création',
    eventDate: "Date de l'événement",
    eventReference: "Référence d'événement",
    eventSource: "Source de l'événement",
    info: 'Veuillez sélectionner au moins un filtre pour lancer une recherche',
  },
  menu: {
    connectors: 'Connecteurs',
    providers: 'Fournisseurs',
    customerAreas: 'Espaces client',
    synchronizations: 'Synchronisations',
    internalImportsHistory: "Historique d'imports",
    sources: 'Sources',
    externalContractors: 'Prestataires externes',
    flags: 'Feature Flags',
    applications: 'Applications',
    events: 'Événements',
  },
  components: {
    fileInput: {
      noFile: 'Aucun fichier',
    },
  },
  resources: {
    connectors: {
      name: 'Connecteur |||| Connecteurs',
      fields: {
        name: 'Nom',
        enabled: 'Activé',
      },
    },
    providers: {
      name: 'Fournisseur |||| Fournisseurs',
      fields: {
        name: 'Nom',
        url: 'Site web',
        logo: 'Logo',
        connectorId: 'Connecteur',
        retrieveFrequency: 'Fréquence de récupération auto',
        providerType: 'Type de fournisseur',
      },
      actions: {
        accessWebsite: 'Accèder au site du fournisseur',
      },
      frequencies: {
        every_day: 'Tous les jours',
        every_month: 'Tous les mois',
        every_two_months: 'Tous les 2 mois',
        every_three_months: 'Tous les 3 mois',
        every_six_months: 'Tous les 6 mois',
        twice_a_month: '2 fois par mois',
        nc: 'Non communiquée',
      },
      types: {
        juicy: 'Juicy',
        chorus: 'Chorus',
      },
    },
    'customer-areas': {
      name: 'Espace client |||| Espaces client',
      fields: {
        name: 'Nom',
        clientId: 'Client',
        referee: 'Mail du référent',
        providerId: 'Fournisseur',
        synchronizations: 'Synchronisations',
        login: 'Identifiant',
        password: 'Mot de passe',
        enabled: 'Activé',
        minRetrieveDate: 'Date minimum de récupération',
        nextAutoSyncBeyond: 'Date de prochaine sync auto',
        frequency: 'Fréquence',
        retrieveFrequency: 'Fréquence de récupération auto',
        ignoreExistingGroup: 'Ignorer la création du groupement si déjà existant',
      },
      helpers: {
        enabled: "Désactivé si le fournisseur n'a pas de connecteur",
      },
      validators: {
        refereeMail: 'Doit être un mail Citron®',
      },
      frequencies: {
        deactivated: { long: 'Désactivée', short: 'DE' },
        inherited: {
          long: 'Importée du fournisseur (%{value})',
          short: '%{value}',
        },
        every_day: { long: 'Tous les jours', short: '1D' },
        every_month: { long: 'Tous les mois', short: '1M' },
        every_two_months: { long: 'Tous les 2 mois', short: '2M' },
        every_three_months: { long: 'Tous les 3 mois', short: '3M' },
        every_six_months: { long: 'Tous les 6 mois', short: '6M' },
        twice_a_month: { long: '2 fois par mois', short: '2/M' },
        nc: { long: 'Non communiquée', short: 'NC' },
      },
    },
    synchronizations: {
      name: 'Synchronisation |||| Synchronisations',
      fields: {
        uuid: 'Identifiant unique',
        customerAreaId: 'Espace client',
        groupId: 'Groupement',
        startDate: 'Début',
        endDate: 'Fin',
        state: 'État',
        createdAt: 'Date de création',
      },
      jobs: {
        launchedBy: 'Lancé par',
        logs: {
          status: 'Status',
          collectedInvoices: 'Factures récupérées',
          skippedInvoices: 'Factures ignorées',
        },
      },
    },
    'internal-imports': {
      name: 'Import |||| Imports',
      fields: {
        uuid: 'UUID',
        filename: 'Nom du fichier',
        createdBy: 'Créateur',
        createdAt: 'Date de création',
        executionStartedAt: "Date de début d'exécution",
        executionEndedAt: "Date de début d'exécution",
        state: 'État',
        importType: "Type d'import",
        importFile: 'Fichier à importer',
      },
      options: {
        legacy_data: {
          clear_data:
            "Supprimer toutes les anciennes données: cette option supprimera toutes les données patrimoniales des sites/zones présents dans le fichier et ajoutera uniquement celles de l'import",
        },
      },
      importTypes: {
        legacy_data: 'Données patrimoniales',
        rrefs_assignment: 'Assignation des PDLs',
        sites_creation: 'Création de sites / zones',
      },
      logs: {
        info: 'Information',
        error: 'Erreur',
      },
    },
  },
  syncTabs: {
    summary: {
      name: 'Résumé',
      fields: {
        uuid: 'Identifiant unique',
        type: 'Type',
        createdBy: 'Créé par',
        updatedBy: 'Mis à jour par',
        files: 'Fichiers',
        retry: 'Tentatives',
        requestedAt: 'Demandé le',
        importedAt: 'Importé le',
        report: 'Rapport',
      },
    },
    jobs: {
      name: 'Historique',
      fields: {
        jobId: 'Tâche',
        launchedBy: 'Lancée par',
        requestedAt: 'Demandée le',
        importedAt: 'Importé le',
        state: 'État',
        collectStatus: 'État de récupération',
        errorCode: 'Code erreur',
        collectedFiles: 'Fichiers récupérés',
        skippedFiles: 'Fichiers ignorés',
      },
      files: {
        fields: {
          uuid: 'Identifiant unique',
          fileName: 'Nom du fichier',
          state: 'État',
        },
      },
    },
    journal: {
      name: 'Journal',
      fields: {
        jobId: 'Tâche',
        requestedAt: 'Demandée le',
        state: 'État',
        collectStatus: 'État de récupération',
        summaryLogs: 'Logs récapitulatifs',
        rawLogs: 'Logs bruts',
      },
    },
  },
  internalImportsTab: {
    summary: {
      name: 'Résumé',
      fields: {
        uuid: 'UUID',
        createdBy: 'Créé par',
        createdAt: 'Date de création',
        executionStartedAt: 'Lancé le',
        executionEndedAt: 'Terminé le',
      },
    },
    journal: {
      name: 'Journal',
      notAvailable: 'Journaux non disponibles',
      fields: {
        summaryLogs: 'Résumé',
        rawLogs: 'Journaux bruts',
      },
    },
  },
  messages: {
    synchronizationLaunchSuccess: 'Synchronisation lancée',
    synchronizationLaunchError: 'Erreur lors du lancement de la synchronisation',
    importLaunchSuccess: 'Import lancé',
    importLaunchError: "Erreur lors du lancement de l'import",
    logsJournalNotAvailable: "Le journal des logs n'est pas disponible",
    fileUploadSuccess: 'Fichier téléchargé',
    fileUploadError: 'Erreur lors du téléchargement du fichier',
    inactiveFeature: 'Fonctionalité inactive',
    fetchJobStatesError: 'Erreur lors de la récupération de la liste des états',
    fetchJobTypesError: 'Erreur lors de la récupération de la liste des types',
    fetchImportsStatesError: 'Erreur lors de la récupération de la liste des états',
    loading: 'Chargement en cours',
  },
  actions: {
    launch: 'Lancer',
    import: 'Importer',
  },
  monthly: 'Mensuel',
  validation: {
    required: 'Ce champs est obligatoire',
    email: 'Veuillez renseigner un email',
    emailOrApi: 'Veuillez renseigner au moins un moyen de communication avec le prestataire (email ou url et api key)',
    apiKey: "Vous avez renseigné une url, veuillez indiquer l'api key correspondante",
    url: "Vous avez renseigné une api key, veuillez indiquer l'url correspondante",
    length: 'Ce champs est limité à 280 caractères',
    noDuplicate: 'Vous avez renseigné deux fois la même valeur',
  },
};
